import { cocoonFeatures } from '@cocoonspace/shared/config/cocoon-features-config'
import { ChevronsUpDown, Trash2 } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useState } from 'react'
import { Badge } from '~/components/ui/badge'
import { Button } from '~/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu'
import { cn } from '~/lib/utils'

const filterableFeatures = cocoonFeatures
	.filter((feat) => feat.isSearchFilter)
	.map((feat) => ({
		...feat,
		title: `values:values.features.${feat.id}`,
	}))

export const ServicesFilter = ({
	value,
	onChange,
	...props
}: {
	value?: string[]
	onChange: (obj: string[]) => void
	className?: string
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const { t } = useTranslation()

	const onSelect = (item: any) => {
		const newValue = value?.includes(item.id)
			? value.filter((i) => i !== item.id)
			: [...(value || []), item.id]

		onChange(newValue)
	}

	return (
		<div {...props}>
			<DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
				<div className='relative'>
					<DropdownMenuTrigger asChild>
						<Button
							variant='outline'
							role='combobox'
							aria-expanded={isOpen}
							className={cn('w-full justify-between gap-1', {
								'pr-10': !!value?.length,
							})}
						>
							<div className='flex flex-1 flex-row gap-2 truncate'>
								{filterableFeatures
									.filter((item) => value?.includes(item.id))
									.map((item) => (
										<Badge key={item.id}>{t(item.title)}</Badge>
									))}
							</div>

							{!value?.length && (
								<ChevronsUpDown className='ml-auto size-4 w-3 opacity-50' />
							)}
						</Button>
					</DropdownMenuTrigger>

					{!!value?.length && (
						<button
							type='button'
							className='absolute top-px right-4 bottom-px bg-backround'
							onClick={() => onChange([])}
						>
							<Trash2 className='size-4' />
						</button>
					)}
				</div>

				<DropdownMenuContent>
					{filterableFeatures.map((item) => (
						<DropdownMenuCheckboxItem
							checked={value?.includes(item.id)}
							onCheckedChange={() => onSelect(item)}
							className='gap-2'
						>
							{t(item.title)}

							<div className='ml-auto flex w-[44px] items-center justify-center'>
								<Image
									src={`/images/features/${item.id}.svg`}
									alt={item.id}
									width='44'
									height='35'
									className={item.id === 'elevator' ? 'ml-[3px]' : ''}
								/>
							</div>
						</DropdownMenuCheckboxItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}
